import React from "react";
import { v4 as uuidv4 } from 'uuid';

import { rest, t } from "../../../auto/js/services";
import { getServiceUri } from "../../../auto/js/metadata";

import "../../../auto/js/pages/base.css"
import "../../../main/js/pages/form.css"
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";

const resultColumns = [
	{title: "affiliationId", field: "id"},
    {title: "name", field: "name"},
    {title: "appointmentDate", field: "appointmentDate"},
    {title: "appointmentTime", field: "appointmentTime"},
    {title: "ppt", field: "recordId"}
];


class FormComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			verifyResult: undefined
		}
        rest.request(getServiceUri() +  '/employ-affiliation-service/get-employ-affiliations', "POST", null).then(
            response => {
                this.setState({verifyResult: filterData(response) })
            }
        )
    }



    render() {
        let ResultList = createTableComponent(resultColumns);

        return (
            
            <ResultList loadData={this.state.verifyResult} localData={true} />
                
        )
    }
}

export const displayEmployAffiliationList = (gui) => {
    const readAffiliationForm = () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent gui={gui} />
        }
    }
    gui.goTo(readAffiliationForm);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.appointmentDate
        if ( date !== null)
        row.appointmentDate = date[2] + '-' + date[1] + '-' + date[0];
        let time = row.appointmentTime
        if (time !== null) {
            let h = time[3] < 10 ? '0' + time[3] : time[3];
            let m = time[4] < 10 ? '0' + time[4] : time[4];
            row.appointmentTime = h + ":" + m;
        }
      newRows.push(row);
    }
    return newRows;
}

export const RETORNADOS_RECORD_ORDER_BY_ID = "ID";
export const RETORNADOS_RECORD_ORDER_BY_NAME = "NAME";
export const RETORNADOS_RECORD_ORDER_BY_SURNAMES = "SURNAMES";
export const RETORNADOS_RECORD_ORDER_BY_IDENTIFICATION_NUMBER = "IDENTIFICATION_NUMBER";
export const RETORNADOS_RECORD_ORDER_BY_IDENTIFICATION_TYPE = "IDENTIFICATION_TYPE";
export const RETORNADOS_RECORD_ORDER_BY_BIRTHDATE = "BIRTHDATE";
export const RETORNADOS_RECORD_ORDER_BY_AGE = "AGE";
export const RETORNADOS_RECORD_ORDER_BY_STATUS = "STATUS";
export const RETORNADOS_RECORD_ORDER_BY_GENDER = "GENDER";
export const RETORNADOS_RECORD_ORDER_BY_GENDER_IDENTITY = "GENDER_IDENTITY";
export const RETORNADOS_RECORD_ORDER_BY_ETHNIC_GROUP_MEMBERSHIP = "ETHNIC_GROUP_MEMBERSHIP";
export const RETORNADOS_RECORD_ORDER_BY_DISABILITY = "DISABILITY";
export const RETORNADOS_RECORD_ORDER_BY_OBSERVATIONS = "OBSERVATIONS";
export const RETORNADOS_RECORD_ORDER_BY_HAS_FAMILY_NUCLEUS = "HAS_FAMILY_NUCLEUS";
export const RETORNADOS_RECORD_ORDER_BY_IS_HOUSEHOLD_HEAD = "IS_HOUSEHOLD_HEAD";
export const RETORNADOS_RECORD_ORDER_BY_HOUSE_ID = "HOUSE_ID";
export const RETORNADOS_RECORD_ORDER_BY_HOUSE_TYPE = "HOUSE_TYPE";
export const RETORNADOS_RECORD_ORDER_BY_ADDRESS = "ADDRESS";
export const RETORNADOS_RECORD_ORDER_BY_EMAIL = "EMAIL";
export const RETORNADOS_RECORD_ORDER_BY_PHONE = "PHONE";
export const RETORNADOS_RECORD_ORDER_BY_PHOTO = "PHOTO";

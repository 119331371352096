export const ProgramType = {
	"HEALTH_AFFILIATION": 1
	
}
Object.freeze(ProgramType);
const toTypeMap = {};
Object.keys(ProgramType).forEach(key => {
	toTypeMap[ProgramType[key]] = key;
});
export function getProgramType (value) {
	return toTypeMap[value];
}

export function getAllProgramType () {
    return Object.keys(ProgramType);
}

export const PROGRAM_TYPE_HEALTH_AFFILIATION = "HEALTH_AFFILIATION";

import React from "react";
import { IdType } from "../../../../auto/js/metadata/IdType";
import { swapObject } from "../../../../auto/js/utils";
import { VisitedHealthFacility } from "../../../../auto/js/metadata/VisitedHealthFacility";
import { MoneySources } from "../../../../auto/js/metadata/MoneySources";
import { OccupationStatus } from "../../../../auto/js/metadata/OccupationStatus";
import { HousingType } from "../../../../auto/js/metadata/HousingType";
import { HousingLink } from "../../../../auto/js/metadata/HousingLink";

export const needsFields = [
    {name: "discriminationForGender", type:"checkbox", x:1, y:4, layout:"col-md-12"},
    {name: "discriminationForEthnicOrigin", type:"checkbox", x:1, y:5, layout:"col-md-12"},
    {name: "discriminationForSexualOrientation", type:"checkbox", x:1, y:6, layout:"col-md-12"},
    {name: "youOrHouseholdNeededDoctorInThreeMonths", type:"checkbox", x:1, y:7, layout:"col-md-12"},
    {name: "youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities", type:"select", x:1, y:8, layout:"col-md-12", metadata: () => swapObject(VisitedHealthFacility)},
    {name: "wasItAttendedToHealthCare", type:"checkbox", x:1, y:9, layout:"col-md-12"},
    {name: "hasSocialSecurityAffiliationProcess", type:"checkbox", x:1, y:10, layout:"col-md-12"},
    {name: "householdMembersBetweenEighteenAndFiveAttendingSchool", type:"checkbox", x:2, y:11, layout:"col-md-12"},
    {name: "householdMainSourceOfMoneyLastMonth", type:"select", x:1, y:12, layout:"col-md-12", metadata: () => swapObject(MoneySources)},
    {name: "householdSecondSourceOfMoneyLastMonth", type:"select", x:1, y:13, layout:"col-md-12", metadata: () => swapObject(MoneySources)},
    {name: "currentEmploymentStatusOccupation", type:"select", x:1, y:14, layout:"col-md-12", metadata: () => swapObject(OccupationStatus)},
    {name: "householdTypeOfHousing", type:"select", x:1, y:15, layout:"col-md-12", metadata: () => swapObject(HousingType)},
    {name: "roomNumber", type:"number", x:1, y:16, layout:"col-md-12"},
    {name: "linkWithHousingForm", type:"select", x:2, y:17, layout:"col-md-12", metadata: () => swapObject(HousingLink)}
]
export const form2dto = (formData, dto) => {
    if (formData.photo)
        if (!formData.photo.isEmpty) {
            let base64Image = formData.photo.url;
            let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
            dto.photo = base64;
        }
        else
            dto.photo = null;
    if (formData.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities != null)
        dto.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities = swapObject(VisitedHealthFacility)[formData.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities]
    if (formData.identificationType != null)
        dto.identificationType = swapObject(IdType)[formData.identificationType]
    if (formData.householdMainSourceOfMoneyLastMonth != null)
        dto.householdMainSourceOfMoneyLastMonth = swapObject(MoneySources)[formData.householdMainSourceOfMoneyLastMonth]
    if (formData.householdSecondSourceOfMoneyLastMonth != null)
        dto.householdSecondSourceOfMoneyLastMonth = swapObject(MoneySources)[formData.householdSecondSourceOfMoneyLastMonth]
    if (formData.currentEmploymentStatusOccupation != null)
        dto.currentEmploymentStatusOccupation = swapObject(OccupationStatus)[formData.currentEmploymentStatusOccupation]
    if (formData.householdTypeOfHousing != null)
        dto.householdTypeOfHousing = swapObject(HousingType)[formData.householdTypeOfHousing]
    if (formData.linkWithHousingForm != null)
        dto.linkWithHousingForm = swapObject(HousingLink)[formData.linkWithHousingForm]
}

export const dto2form = (dto) => {
    let form = dto;
    let photo = null;
    if (dto.photo != null) {
        let mimeType = "image/png";
        photo = "data:".concat(mimeType, ";base64,", dto.photo)			
    }
    form['photo'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (photo != null)?photo: '/public/avatar.png', isEmpty: (photo != null)?false:true};
    if (dto.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities != null)
        form.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities = VisitedHealthFacility[dto.youOrHouseholdNeededDoctorVisitAnyHealthcareFacilities];
     if (dto.identificationType != null)
        form.identificationType = IdType[dto.identificationType]
    if (dto.householdMainSourceOfMoneyLastMonth != null)
        form.householdMainSourceOfMoneyLastMonth = MoneySources[dto.householdMainSourceOfMoneyLastMonth]
    if (dto.householdSecondSourceOfMoneyLastMonth != null)
        form.householdSecondSourceOfMoneyLastMonth = MoneySources[dto.householdSecondSourceOfMoneyLastMonth]
    if (dto.currentEmploymentStatusOccupation != null)
        form.currentEmploymentStatusOccupation = OccupationStatus[dto.currentEmploymentStatusOccupation]
    if (dto.householdTypeOfHousing != null)
        form.householdTypeOfHousing = HousingType[dto.householdTypeOfHousing]
    if (dto.linkWithHousingForm != null)
        form.linkWithHousingForm = HousingLink[dto.linkWithHousingForm]
    return form;
}
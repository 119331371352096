import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import { StepperComponent } from "../widgets/StepperComponent";
import { rest, t } from "../../../auto/js/services";
import { getServiceUri } from "../../../auto/js/metadata";
import { ProgressTrackerComponent } from "../widgets/ProgressTrackerComponent";

class AffiliationComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			stepsList: undefined
		}
        let personId = {id: props.id}
        rest.request(getServiceUri() + '/person-affiliations-service/get-person-affiliations', 'POST', personId).then(response => {
            let affiliations = {};
            response.forEach(mtlb => {
                affiliations[mtlb.name] = {steps: mtlb.statusList, activeStep: mtlb.statusList.indexOf(mtlb.activeStatus)};
            });
            this.setState({stepsList: affiliations});
        })
    }


    buildSteps = () => {
        let items = [];
        Object.keys(this.state.stepsList).forEach(key => {
            items.push(
                <>
                <h5>{t(key)}</h5>
                <ProgressTrackerComponent steps={this.state.stepsList[key].steps} activeStep={this.state.stepsList[key].activeStep} />
                <hr style={{ borderTop: '2px solid #e2e7e6' }} />
                </>
            )
        });
        return items
    }


    render() {
        return (         
            <>
            {(this.state.stepsList) && this.buildSteps()}
            </>
        )
    }
}

export const displayReadAffiliationsStatus = (onFinish) => (id) => {
    
    let uuid = uuidv4();
	return {
		uuid, view: () => <AffiliationComponent id={id}/>
	};
}
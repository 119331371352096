import { rest } from "../services/RestClient";

export interface UserInfo {
	firstname: string,
	lastname: string,
	username: string,
	pictureUrl?: string,
	roles: string[]
}

var myinfo: UserInfo;

export const whoami = 
	() => myinfo;
		
export const loadUserInfo = async () => {
	let info = await rest.request('/api/users/whoami', 'GET');
	myinfo = {
		firstname: info.firstName,
		lastname: info.lastName,
		username: info.username,
		roles: info.roles,
		pictureUrl: info.pictureUrl
	}
	return myinfo;
}
import { Centrifuge } from 'centrifuge';
import React from 'react';
import { CentrifugoAdapter } from "statusbar-widget";
import { rest, t } from '../../../auto/js/services';
import { StatusBar, StatusListener } from "statusbar-widget";

export class StatusBarComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            serviceList: [
                {name: "ppt", status: 1, label: t`ppt`},
                {name: "alcaldia", status: 1, label: t`alcaldia`},
                {name: "adres", status: 1, label: t`adres`},
                {name: "pnud", status: 1, label: t`pnud`},
                {name: "cp", status: 1, label: t`cp`}
            ]
        }

        this.centrifuge = new Centrifuge("wss://integrate.co.chahinehamila.com/connection/websocket", {
            token: rest.oidcService.token,
            getToken: function () {
              return new Promise((resolve, reject) => {
                rest.oidcService.updateToken(55).then(function () {
                  resolve(rest.oidcService.token);
                }).catch(function (err) {
                  reject(err);
                  rest.oidcService.logout();
                });
              })
            }
          });
        
          this.centrifuge.on('connecting', function (ctx) {
            console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
          }).on('connected', function (ctx) {
            console.log(`connected over ${ctx.transport}`);
          }).on('disconnected', function (ctx) {
            console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
          }).connect();
          this.sub = this.centrifuge.newSubscription("services_status");
          
          this.centrifugoAdapter = new CentrifugoAdapter(this.sub);
    }

    handleItemUpdate = (updatedItem) => {
      this.setState((prevState) => ({
          serviceList: prevState.serviceList.map((item) =>
          item.name === updatedItem.name
            ? { ...item, status: parseInt(updatedItem.status) }
            : item
        )
      }));
    };

      render() {
        
        return (
            <>
            <StatusBar itemList={this.state.serviceList} />
            <StatusListener
                onItemUpdate={this.handleItemUpdate}
                communicationSystem={this.centrifugoAdapter}
            />              
            </>
        )
    } 

}
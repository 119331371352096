import * as React from 'react';
import { useField } from "formik";
import InputBase from '@mui/material/InputBase';
import TextField from "@material-ui/core/TextField";
import { alpha, styled } from '@mui/material/styles';
import { FormControl, InputLabel } from '@mui/material';

export function TextInput (props) {
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
	const { setValue } = helpers;
	const handleChange = event => {
		setValue(event.target.value);
	};

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		'label + &': {
		  marginTop: theme.spacing(3),
		}/* ,
		'& .MuiInputBase-input': {
		  borderRadius: 4,
		  position: 'relative',
		  backgroundColor: '#fafafa',
		  border: '1px solid',
		  borderColor: '#ebf0ed',
		  borderRadius: '10px',
		  fontSize: 16,
		  width: '100%',
		  padding: '10px 12px',
		  transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		  ]),
		  // Use the system font instead of the default Roboto font.
		  fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		  ].join(','),
		  '&:focus': {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		  },
		}, */
	  }));

	return (
		<FormControl variant="standard">
		<InputLabel shrink htmlFor={props.name}>
			{props.label}
        </InputLabel>
		<TextField
			id={props.name}
			label={props.label}
			multiline
			rowsMax="4"
			value={value}
			onChange={handleChange}
			type={props.type}
			disabled={props.disabled}
			InputLabelProps={{shrink: false}}
		/>
		</FormControl>
	);
}
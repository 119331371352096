export const HouseType = {
	"GRAND_MOTHER": 1
	,
	"DAUGHTER": 2
	,
	"SON": 3
	,
	"FATHER": 4
	,
	"MOTHER": 5
	
}
Object.freeze(HouseType);
const toTypeMap = {};
Object.keys(HouseType).forEach(key => {
	toTypeMap[HouseType[key]] = key;
});
export function getHouseType (value) {
	return toTypeMap[value];
}

export function getAllHouseType () {
    return Object.keys(HouseType);
}

export const HOUSE_TYPE_GRAND_MOTHER = "GRAND_MOTHER";
export const HOUSE_TYPE_DAUGHTER = "DAUGHTER";
export const HOUSE_TYPE_SON = "SON";
export const HOUSE_TYPE_FATHER = "FATHER";
export const HOUSE_TYPE_MOTHER = "MOTHER";

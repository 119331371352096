import React from 'react';

import styled from 'styled-components';
import { createBrowserComponent } from "../router/Browser";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Main = styled.main`
    position: relative;
    overflow: hidden;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
    padding: 20px
`;

const buildTabs = (tabs) => {
    let items = [];
    Object.keys(tabs).forEach(t => {
        items.push(
            <Tab className='browser-tab' eventKey={t} title={<span>{tabs[t].title}<p style={{height: 'auto', marginBottom: '0'}}>{tabs[t].description}</p></span>}>
            </Tab>
        )
    });
    return items
}

export class TabBrowser extends createBrowserComponent() {
    constructor(props) {
        super(props)
        this.state.activeTab = props.activeTab;
        this.tabs = {};
        this.gui = props.gui;
    }

    handTabSelection = (k) => {
        this.tabs[k].do();
        this.setState({activeTab: k})
    }     

    render() {
        return (
            <>
            <div className='browser-tabs-header'>
            {(this.props.tabsHeader) && this.props.tabsHeader}
                <Tabs
                    id="browser-tab"
                    activeKey={this.state.activeKey}
                    defaultActiveKey={this.props.activeKey}
                    onSelect={(k) => this.handTabSelection(k)}
                    className='browser-tabs'
                    justify
                >
                    {buildTabs(this.tabs)}
                </Tabs>
            </div>
                <Main>
                {this.buildDesktop()}
                </Main>
            </>
        )
    }
}
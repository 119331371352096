import React, { useState } from 'react'
import { Field } from "formik";

import { ImageViewer } from './ImageViewer';
import { ImageEditor } from './ImageEditor';
import { formState } from '../forms/FormState';

/** AvatarField props: 
	name: field name
	upload(file)->avatarId, 
	getImageUrl(avatarId)->url, 
	getDefaultImage()->{avatarId, x, y, scale, rotate, url}, 
*/
export function ImageInput(props) {
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(undefined);

	const onClick = () => {
		setOpen(true);
	};

	const deleteImage = () => {
		props.deleteAvatar.then(image => setImage(image))
	};

	const onCancel = () => {
		setOpen(false);
	};
	return (
		<Field name={props.name} id={props.key}>
		{({ field: { value }, form: { setFieldValue } }) => (
		<>
			<ImageViewer
				image={value}
				onClick={props.disabled? undefined : onClick}
			/>
			{props.disabled? '' : 
			<ImageEditor
				image={value}
				open={open}
				onOk={changedImage => { setFieldValue(props.name, changedImage); setOpen(false); setImage(changedImage); formState.setImage(changedImage)} }
				onCancel={onCancel}
			/>}
		</>
		)}
		</Field>
	)
}
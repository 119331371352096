import React from "react";
import { IdType } from "../../../../auto/js/metadata/IdType";
import { swapObject } from "../../../../auto/js/utils";
import { Gender } from "../../../../auto/js/metadata/Gender";
import { GenderIdentity } from "../../../../auto/js/metadata/GenderIdentity";
import { EthnicGroup } from "../../../../auto/js/metadata/EthnicGroup";
import { RetornadosStatus, getRetornadosStatus } from "../../../../auto/js/metadata/RetornadosStatus";


export const retornadosRecordFields = [
  /*   {name: "photo", type:"image", x:1, y:1, layout:"col-md-12"},
    {name: "name", type:"text", x:1, y:2, layout:"col-md-6"},
    {name: "surnames", type:"text", x:2, y:2, layout:"col-md-6"},
    {name: "identificationNumber", type:"text", x:1, y:3, layout:"col-md-6"},
    {name: "identificationType", type:"select", x:2, y:3, layout:"col-md-6", metadata: () => swapObject(IdType)},
    {name: "birthdate", type:"date", x:1, y:4, layout:"col-md-6"},
    {name: "age", type:"number", x:2, y:4, layout:"col-md-6"}, */
    {name: "gender", type:"select", x:1, y:5, layout:"col-md-6", metadata: () => swapObject(Gender)},
    {name: "genderIdentity", type:"select", x:2, y:5, layout:"col-md-6", metadata: () => swapObject(GenderIdentity)},
    {name: "ethnicGroupMembership", type:"select", x:1, y:6, layout:"col-md-6", metadata: () => swapObject(EthnicGroup)},
    {name: "observations", type:"text", x:1, y:7, layout:"col-md-6"},
    {name: "hasFamilyNucleus", type:"checkbox", x:1, y:8, layout:"col-md-6"},
    {name: "isHouseholdHead", type:"checkbox", x:2, y:8, layout:"col-md-6"},
    {name: "address", type:"text", x:1, y:9, layout:"col-md-6"},
    {name: "email", type:"text", x:1, y:10, layout:"col-md-6"},
    {name: "phone", type:"text", x:2, y:10, layout:"col-md-6"},
]
export const form2dto = (formData, dto) => {
    if (!formData.photo.isEmpty) {
		let base64Image = formData.photo.url;
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.photo = base64;
	}
    else
        dto.photo = null;
    if (formData.gender != null)
        dto.gender = swapObject(Gender)[formData.gender]
    if (formData.identificationType != null)
        dto.identificationType = swapObject(IdType)[formData.identificationType]
    if (formData.ethnicGroupMembership != null)
        dto.ethnicGroupMembership = swapObject(EthnicGroup)[formData.ethnicGroupMembership]
    if (formData.genderIdentity != null)
        dto.genderIdentity = swapObject(GenderIdentity)[formData.genderIdentity]
    dto.status = getRetornadosStatus(RetornadosStatus.APPROVED);
}

export const dto2form = (dto) => {
    let form = dto;
    let photo = null;
    if (dto.photo != null) {
        let mimeType = "image/png";
        photo = "data:".concat(mimeType, ";base64,", dto.photo)			
    }
    form['photo'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (photo != null)?photo: '/public/avatar.png', isEmpty: (photo != null)?false:true};
    if (dto.gender != null)
        form.gender = Gender[dto.gender];
     if (dto.identificationType != null)
        form.identificationType = IdType[dto.identificationType]
    if (dto.ethnicGroupMembership != null)
        form.ethnicGroupMembership = EthnicGroup[dto.ethnicGroupMembership]
    if (dto.genderIdentity != null)
        form.genderIdentity = GenderIdentity[dto.genderIdentity]
    return form;
}
export const VisitedHealthFacility = {
	"YES_FORMAL_HEALTHCARE_FACILITY": 1
	,
	"YES_INFORMAL_HEALTHCARE_FACILITY": 2
	,
	"NO": 3
	,
	"I_DO_NOT_KNOW": 4
	
}
Object.freeze(VisitedHealthFacility);
const toTypeMap = {};
Object.keys(VisitedHealthFacility).forEach(key => {
	toTypeMap[VisitedHealthFacility[key]] = key;
});
export function getVisitedHealthFacility (value) {
	return toTypeMap[value];
}

export function getAllVisitedHealthFacility () {
    return Object.keys(VisitedHealthFacility);
}

export const VISITED_HEALTH_FACILITY_YES_FORMAL_HEALTHCARE_FACILITY = "YES_FORMAL_HEALTHCARE_FACILITY";
export const VISITED_HEALTH_FACILITY_YES_INFORMAL_HEALTHCARE_FACILITY = "YES_INFORMAL_HEALTHCARE_FACILITY";
export const VISITED_HEALTH_FACILITY_NO = "NO";
export const VISITED_HEALTH_FACILITY_I_DO_NOT_KNOW = "I_DO_NOT_KNOW";

import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { t } from "../../../auto/js/services";

export class StepperComponent extends React.Component {
    constructor(props) {
        super(props)
        this.steps = props.steps;
    }

    render() {
        
        return (
            <Box sx={{ width: '100%', marginTop: '25px' }}>
            <Stepper activeStep={this.props.activeStep} alternativeLabel>
            {this.steps.map((name) => (
                <Step key={name}>
                <StepLabel>{t(name)}</StepLabel>
                </Step>
            ))}
            </Stepper>
        </Box>
        );
    }
  }
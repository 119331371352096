export const IdType = {
	"PPT": 1
	
}
Object.freeze(IdType);
const toTypeMap = {};
Object.keys(IdType).forEach(key => {
	toTypeMap[IdType[key]] = key;
});
export function getIdType (value) {
	return toTypeMap[value];
}

export function getAllIdType () {
    return Object.keys(IdType);
}

export const ID_TYPE_PPT = "PPT";

import React from "react";
import { HOUSE_HOLD_ORDER_BY_NAME } from "../../../../auto/js/metadata/HouseHoldOrderBy";
import { HouseType } from "../../../../auto/js/metadata/HouseType";

export const houseHoldListColumns = [
    {title: "id", field: "id"},
    {title: "name", field: "name"}
];

export const getOrderBy = () => {
    return HOUSE_HOLD_ORDER_BY_NAME;
}

export const filterData = (defaultRows) => {
    return defaultRows;
}
export const GenderIdentity = {
	"CISGENDER": 1
	,
	"TRANSGENDER": 2
	,
	"OTHER": 3
	,
	"NO_ANSWER": 4
	
}
Object.freeze(GenderIdentity);
const toTypeMap = {};
Object.keys(GenderIdentity).forEach(key => {
	toTypeMap[GenderIdentity[key]] = key;
});
export function getGenderIdentity (value) {
	return toTypeMap[value];
}

export function getAllGenderIdentity () {
    return Object.keys(GenderIdentity);
}

export const GENDER_IDENTITY_CISGENDER = "CISGENDER";
export const GENDER_IDENTITY_TRANSGENDER = "TRANSGENDER";
export const GENDER_IDENTITY_OTHER = "OTHER";
export const GENDER_IDENTITY_NO_ANSWER = "NO_ANSWER";

export const HousingLink = {
	"MONTHLY_RENT_PAYMENT": 1
	,
	"RENT_PAYMENT_BY_DAYS_OR_WEEKLY": 2
	,
	"OWNER_OF_THE_PROPERTY": 3
	
}
Object.freeze(HousingLink);
const toTypeMap = {};
Object.keys(HousingLink).forEach(key => {
	toTypeMap[HousingLink[key]] = key;
});
export function getHousingLink (value) {
	return toTypeMap[value];
}

export function getAllHousingLink () {
    return Object.keys(HousingLink);
}

export const HOUSING_LINK_MONTHLY_RENT_PAYMENT = "MONTHLY_RENT_PAYMENT";
export const HOUSING_LINK_RENT_PAYMENT_BY_DAYS_OR_WEEKLY = "RENT_PAYMENT_BY_DAYS_OR_WEEKLY";
export const HOUSING_LINK_OWNER_OF_THE_PROPERTY = "OWNER_OF_THE_PROPERTY";

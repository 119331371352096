import React from 'react';

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl: boolean;
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children }) => {
  return (
      <div className='sidebar-header'>
          {children}
      </div>
  );
};
import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { needsFields, form2dto, dto2form } from "../../../../main/js/forms/needs/NeedsCommon";

let NeedsForm = createFormComponent(needsFields);
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<NeedsForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
		</>
		)
	}

}

export const displayNewNeedsForm = (onFinish) => (id) => {

	const save = async (formData) => {
		let data = _.clone(formData);
		let dto = pojoMetadata['needs'].form2dto(data);
		form2dto(formData, dto);
		dto.identificationNumber = id;
		try {
			return rest.request(getServiceUri() + 'needs/new-document', 'POST', dto).then((response) =>{
				if (response.status)
					showNotification(response.message.split('Detail: ')[1], "error")
					return response;
			});
		} catch (err) {
			alert(err);
		}
	}
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(needsFields)} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save}/>
	};
}

export const displayReadNeedsForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
		}
	}
	return empty;
}

const loadFormData = async (id) => {
	return await rest.read('needs', id).then(response => {
		let form = dto2form(response);
		return form;
	})
}

const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['needs'].form2dto(data);
	form2dto(formData, dto)
	try {
        return rest.request(getServiceUri() + 'needs/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
		});
    } catch (err) {
        alert(err);
    }
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
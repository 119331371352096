import React from "react";
import { v4 as uuidv4 } from 'uuid';

import { rest, t } from "../../../auto/js/services";
import { getServiceUri } from "../../../auto/js/metadata";

import "../../../auto/js/pages/base.css"
import "../../../main/js/pages/form.css"
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";

const resultColumns = [
	{title: "affiliationId", field: "id"},
    {title: "name", field: "name"},
    {title: "affiliationDate", field: "affiliationDate"},
    {title: "affiliationStatus", field: "affiliationStatus"},
    {title: "ppt", field: "recordId"}
];


class FormComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			verifyResult: undefined
		}
        rest.request(getServiceUri() +  '/education-affiliation-service/get-education-affiliations', "POST", null).then(
            response => {
                this.setState({verifyResult: filterData(response) })
            }
        )
    }



    render() {
        let ResultList = createTableComponent(resultColumns);

        return (
            
            <ResultList loadData={this.state.verifyResult} localData={true} />
                
        )
    }
}

export const displayEducationAffiliationList = (gui) => {
    const readAffiliationForm = () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent gui={gui} />
        }
    }
    gui.goTo(readAffiliationForm);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.affiliationDate
        if ( date !== null)
        row.affiliationDate = date[2] + '-' + date[1] + '-' + date[0];
        if (row.affiliationStatus === 16)
            row.affiliationStatus = t`READY_FOR_APPROVAL`
        if (row.affiliationStatus === 3)
            row.affiliationStatus = t`APPROVED`
      newRows.push(row);
    }
    return newRows;
}

import { formState } from "../../../auto/js/forms/FormState";
import { displayReadRetornadosRecordForm } from "../../../auto/js/forms/retornadosRecord/RetornadosRecordForm";
import { Task } from "./Task";

export class CaracterizationTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        this.gui.goTo(displayReadRetornadosRecordForm(this.complete), formState.getState().retornadosId, null)
    }
}
export const Gender = {
	"MALE": 1
	,
	"FEMALE": 2
	,
	"NON_BINARY": 3
	,
	"OTHER": 4
	
}
Object.freeze(Gender);
const toTypeMap = {};
Object.keys(Gender).forEach(key => {
	toTypeMap[Gender[key]] = key;
});
export function getGender (value) {
	return toTypeMap[value];
}

export function getAllGender () {
    return Object.keys(Gender);
}

export const GENDER_MALE = "MALE";
export const GENDER_FEMALE = "FEMALE";
export const GENDER_NON_BINARY = "NON_BINARY";
export const GENDER_OTHER = "OTHER";

export const MoneySources = {
	"SALARY_INCOME": 1
	,
	"INCOME_FROM_WORK_PER_DAY": 2
	,
	"INCOME_FROM_BUSINESS_INCOME": 3
	,
	"SUPPORT_FROM_FAMILY_COMMUNITY_MEMBERS_ABROAD": 4
	,
	"SOCIAL_SECURITY": 5
	,
	"UN_NGO_ASSISTANCE": 6
	,
	"RENTAL_INCOME": 7
	,
	"SELL_YOUR_OWN_GOODS": 8
	,
	"USE_OF_LOANS": 9
	,
	"USE_SAVINGS": 10
	,
	"OTHERS": 11
	
}
Object.freeze(MoneySources);
const toTypeMap = {};
Object.keys(MoneySources).forEach(key => {
	toTypeMap[MoneySources[key]] = key;
});
export function getMoneySources (value) {
	return toTypeMap[value];
}

export function getAllMoneySources () {
    return Object.keys(MoneySources);
}

export const MONEY_SOURCES_SALARY_INCOME = "SALARY_INCOME";
export const MONEY_SOURCES_INCOME_FROM_WORK_PER_DAY = "INCOME_FROM_WORK_PER_DAY";
export const MONEY_SOURCES_INCOME_FROM_BUSINESS_INCOME = "INCOME_FROM_BUSINESS_INCOME";
export const MONEY_SOURCES_SUPPORT_FROM_FAMILY_COMMUNITY_MEMBERS_ABROAD = "SUPPORT_FROM_FAMILY_COMMUNITY_MEMBERS_ABROAD";
export const MONEY_SOURCES_SOCIAL_SECURITY = "SOCIAL_SECURITY";
export const MONEY_SOURCES_UN_NGO_ASSISTANCE = "UN_NGO_ASSISTANCE";
export const MONEY_SOURCES_RENTAL_INCOME = "RENTAL_INCOME";
export const MONEY_SOURCES_SELL_YOUR_OWN_GOODS = "SELL_YOUR_OWN_GOODS";
export const MONEY_SOURCES_USE_OF_LOANS = "USE_OF_LOANS";
export const MONEY_SOURCES_USE_SAVINGS = "USE_SAVINGS";
export const MONEY_SOURCES_OTHERS = "OTHERS";

import React from "react";
import { RETORNADOS_RECORD_ORDER_BY_NAME } from "../../../../auto/js/metadata/RetornadosRecordOrderBy";

export const retornadosRecordListColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "name", field: "name"},
    {title: "surnames", field: "surnames"},
    {title: "birthdate", field: "birthdate"},
    {title: "identificationNumber", field: "identificationNumber"},
]

export const getOrderBy = () => {
    return RETORNADOS_RECORD_ORDER_BY_NAME;
}

export const filterData = (data) => {
    const newRows = [];
    for (let i in data) {
        let row = data[i];
        let photo = null;
        if (row.photo != null) {
			let mimeType = "image/png";
			photo = "data:".concat(mimeType, ";base64,", row.photo);		
		}
        row.image = (photo != null)?photo:"/public/avatar.png";
        let date = row.birthdate
        if (date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
    }
    return newRows;
}
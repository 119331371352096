import { formState } from "../../../auto/js/forms/FormState";
import { displayNewNeedsForm, displayReadNeedsForm } from "../../../auto/js/forms/needs/NeedsForm";
import { Task } from "./Task";

export class NeedsTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        this.gui.goTo(displayNewNeedsForm(this.complete), formState.getState().ppt, null)
    }
}
import React, { useState } from "react"
import { t } from "../../../auto/js/services";


import './progress-tracker.css'
import './progress-tracker.scss'


export const ProgressTrackerComponent = (props) => {
    const [steps, setSteps] = useState(props.steps);
    const [activeStep, setActiveStep] = useState(props.activeStep);

    const buildProgressTracker = () => {
        let items = [];
        let activatedActiveStep = false;
        steps.map((step, index) => {
            if (activeStep === index) {
                activatedActiveStep = true;
                items.push(
                    <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                        <p class="progress-title">{t(step)}</p>
                        </div>
                    </li>
                )
            }
            else if (!activatedActiveStep) {
                items.push(
                    <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                        <p class="progress-title">{t(step)}</p>
                        </div>
                    </li>
                )
            } else
                items.push(
                    <li class="progress-step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                        <p class="progress-title">{t(step)}</p>
                        </div>
                    </li>
                )
        })
        return items;
    }

    return (
        <>
         <ul class="progress-tracker progress-tracker--text progress-tracker--center">
            {buildProgressTracker()}
         </ul>
        </>
    )
} 
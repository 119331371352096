
class FormState {
    constructor() {
        if (!!FormState.instance) {
            return FormState.instance;
        }

        FormState.instance = this;
        this.image = {isEmpty: true};
        this.addressList = [];
        this.phoneNumberList = [];
        this.emailList = [];
        this.attachmentList = [];
        this.state = {};
        return this;
    }

    getImage() {
        return this.image;
    }

    setImage(image) {
        this.image = image;
    }

    getAddressList() {
        return this.addressList;
    }
    addToAddressList(address) {
        this.addressList.push(address);
    }
    setAddressList(addressList) {
        this.addressList = addressList;
    }
    getPhoneNumberList() {
        return this.phoneNumberList;
    }
    addToPhoneNumberList(phoneNumber) {
        this.phoneNumberList.push(phoneNumber);
    }
    setPhoneNumberList(phoneNumberList) {
        this.phoneNumberList = phoneNumberList;
    }
    getEmailList() {
        return this.emailList;
    }
    addToEmailList(email) {
        this.emailList.push(email);
    }
    setEmailList(emailList) {
        this.emailList = emailList;
    }
    getAttachmentList() {
        return this.attachmentList;
    }
    addToAttachmentList(attachment) {
        this.attachmentList.push(attachment);
    }
    setAttachmentList(attachmentList) {
        this.attachmentList = attachmentList;
    }
    getState() {
        return this.state;
    }
    setState(state) {
        this.state = state;
    }
    addState(key, value) {
        this.state[key] = value;
    }
}
export const formState = new FormState();
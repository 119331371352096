import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { rest, t } from '../../../auto/js/services';
import { displayReadRetornadosRecordForm } from '../../../auto/js/forms/retornadosRecord/RetornadosRecordForm';
import { displayNewNeedsForm, displayReadNeedsForm } from '../../../auto/js/forms/needs/NeedsForm';
import { displayReadHouseHoldForm } from '../../../auto/js/forms/houseHold/HouseHoldForm';
import { showNotification } from "../../../auto/js/utils";
import { TabBrowser } from '../router/TabBrowser';
import { dto2form } from './retornadosRecord/RetornadosRecordCommon';
import { RetornadosComponent } from '../widgets/RetornadosComponents';
import { displayReadAffiliationsStatus } from './AffiliationsStatusView';

export const displayRetornadosTabsView = (gui, id) => {

    class TabsViewComponent extends TabBrowser {
        constructor(props) {
            super(props)
            this.state.houseId = null;
            this.state.needsId = null;
            this.state.identificationNumber = null;
            this.tabs = {
                "caracterization": {
                    title: t`Caracterization`,
                    description: t`Retornados Caracterization form`,
                    do: () => this.goTo(displayReadRetornadosRecordForm(() => showNotification(t`Updated Retornados Caracterization`, "success")), id)
                },
                "needs": {
                    title: t`Needs`,
                    description: t`Retornados Needs form`,
                    do: () => (this.state.needsId != null)?this.goTo(displayReadNeedsForm(() => showNotification(t`Updated Retornados Needs`, "success")), this.state.needsId): this.goTo(displayNewNeedsForm(() => showNotification(t`Created Retornados Needs`, "success")), this.state.identificationNumber)
                },
                "houseHold": {
                    title: t`House Hold`,
                    description: t`Retornados House Hold form`,
                    do: () => this.goTo(displayReadHouseHoldForm(() => showNotification(t`Updated Retornados House Hold`, "success")), this.state.houseId)
                },
                "affiliations": {
                    title: t`Affiliations Status`,
                    description: t`Retornados Affiliations Status`,
                    do : () => this.goTo(displayReadAffiliationsStatus(), this.state.identificationNumber)
                }
            }
            this.goTo(displayReadRetornadosRecordForm(() => showNotification(t`Updated Retornados Caracterization`, "success")), id)   
            loadRetornadosData(id).then((response) => {
                this.setState({houseId: response.houseId, needsId: response.needsId, identificationNumber: response.identificationNumber})
            })
        }
    }

    const createRetornadosTabView = (args) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <TabsViewComponent gui={gui} activeTab={"caracterization"} welcome={() => <></>} tabsHeader={<RetornadosComponent loadData={async () => loadRetornadosData(id)} />}/>
        }
    }
    gui.goTo(createRetornadosTabView, null, null);
}

const loadRetornadosData = async (id) => {
    return await rest.read('retornados-record', id);
}
export const EthnicGroup = {
	"AFRO_DESCENDANT": 1
	,
	"INDIGENOUS": 2
	,
	"ROM_PEOPLE": 3
	,
	"NONE": 4
	,
	"NO_ANSWER": 5
	
}
Object.freeze(EthnicGroup);
const toTypeMap = {};
Object.keys(EthnicGroup).forEach(key => {
	toTypeMap[EthnicGroup[key]] = key;
});
export function getEthnicGroup (value) {
	return toTypeMap[value];
}

export function getAllEthnicGroup () {
    return Object.keys(EthnicGroup);
}

export const ETHNIC_GROUP_AFRO_DESCENDANT = "AFRO_DESCENDANT";
export const ETHNIC_GROUP_INDIGENOUS = "INDIGENOUS";
export const ETHNIC_GROUP_ROM_PEOPLE = "ROM_PEOPLE";
export const ETHNIC_GROUP_NONE = "NONE";
export const ETHNIC_GROUP_NO_ANSWER = "NO_ANSWER";

export const HousingType = {
	"ORGANIZATION_OR_GOVERNMENT_CAMP": 1
	,
	"APARTMENT": 2
	,
	"HOUSE": 3
	,
	"INFORMAL_SETTLEMENT_IMPROVISED_SHELTER": 4
	,
	"SOCIAL_HOUSING_COLLECTIVE_CENTERS": 5
	,
	"OTHER": 6
	
}
Object.freeze(HousingType);
const toTypeMap = {};
Object.keys(HousingType).forEach(key => {
	toTypeMap[HousingType[key]] = key;
});
export function getHousingType (value) {
	return toTypeMap[value];
}

export function getAllHousingType () {
    return Object.keys(HousingType);
}

export const HOUSING_TYPE_ORGANIZATION_OR_GOVERNMENT_CAMP = "ORGANIZATION_OR_GOVERNMENT_CAMP";
export const HOUSING_TYPE_APARTMENT = "APARTMENT";
export const HOUSING_TYPE_HOUSE = "HOUSE";
export const HOUSING_TYPE_INFORMAL_SETTLEMENT_IMPROVISED_SHELTER = "INFORMAL_SETTLEMENT_IMPROVISED_SHELTER";
export const HOUSING_TYPE_SOCIAL_HOUSING_COLLECTIVE_CENTERS = "SOCIAL_HOUSING_COLLECTIVE_CENTERS";
export const HOUSING_TYPE_OTHER = "OTHER";

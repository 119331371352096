import styled from '@emotion/styled';
import React from 'react';

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: boolean;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children }) => {
  return (
    <div className='sidebar-footer'>   
    {children}
    </div>
  );
};
import React from 'react';

import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import { Welcome } from '../../../main/js/pages/Welcome';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';

import { RibbonTab, setContextualOptions } from '../../../auto/js/widgets';
import "./form.css";
import { ToolBar } from '../widgets/ToolBar';

import '@trendmicro/react-breadcrumbs/dist/react-breadcrumbs.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { SideMenu } from '../widgets/SideMenu';
import { RIBBON_MENU, SIDE_MENU } from '../../../auto/js/metadata/MenuType';
import { menuType } from '../../../main/js/router/Menu';
import { ZONE_0, ZONE_1 } from '../metadata/Area';

const Main = styled.main`
    position: relative;
    overflow: hidden;
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
	height: calc(100vh - 100px);
`;

const welcomeUUID = uuidv4();

export class MainPage extends React.Component {
	constructor(props) {
		super(props);
        this.views = new Map();
		this.requestingClose = false
		let v = {uuid: welcomeUUID, view: () => <Welcome key={welcomeUUID}/>, area: ZONE_1};
		this.register(v);
		this.state = {
			focusedView: v,
			
		};
		this.openViewEvent = new Observable();
		this.openViewEvent.subscribe((v) => 
		{
			if (!this.requestingClose)
				this.canISwitchTo(v)
		});
    }

	register = (v) => {
		this.views.set(v.uuid, v, v.area);
	}
	
	unregister = (v) => {
		setContextualOptions({});
		this.views.delete(v.uuid);
	}

	canISwitchTo = async (v) => {
		if (this.state.focusedView) {
			this.requestingClose = true;
			CAN_I_SWITCH.send(this.doSwitchTo(v), () => this.doSwitchTo(v)(true));
		}
	}

	doSwitchTo = (v) => (closeCurrent) => {
		if (closeCurrent)
			this.switchView(v)
		this.requestingClose = false;
	}

	switchView = function (v) {
		// default behavior for now is one view at a time
		// this will eventually change though
		if (this.state.focusedView) {
			if (this.state.focusedView.uuid==v.uuid)
				return;
			else if (this.state.focusedView.area === v.area){
				this.unregister(this.state.focusedView);
				CAN_I_SWITCH.hangUp();
			}
		}
		if (!this.views.has(v.uuid))
			this.register(v);
		this.setState({focusedView:v});
		this.requestingClose = false;
	}
	
	buildDesktop = () => {
		let Desktop;
		for (const [uuid, v] of this.views.entries()) {
			if (v.area) {
				if (v.area === ZONE_1)
					Desktop = v.view();
			}
			else
				Desktop = v.view();
		}
		return Desktop;
	}

	buildNavigator = () => {
		let Navigator;
		for (const [uuid, v] of this.views.entries()) {
			if (v.area === ZONE_0) {
				Navigator = v.view();
			}
		}
		if (Navigator)
			return Navigator;
		else
			return null;
	}
	
	render() {
		return (
			<>
			{(menuType == SIDE_MENU )?
				<div className="row">
					<div class="col-md-2">
						<SideMenu workspace={this}/>
					</div>
					<div className='col-md-10'>
						<ToolBar languages={this.props.languages}/>
						<Main>
						{this.buildNavigator()}
						{this.buildDesktop()}
						</Main>
					</div>
				</div>	
			:(menuType == RIBBON_MENU)?
				<>
				<ToolBar languages={this.props.languages}/>
				<RibbonTab />
				<Main>
				{this.buildDesktop()}
				</Main>
				</>
			:
			null}
			</>
		);
	}
}
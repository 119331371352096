import React from "react";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { buildEmptyObject, createFormComponent } from "../../../auto/js/widgets/FormComponent";
import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { rest, t } from "../../../auto/js/services";
import { getServiceUri } from "../../../auto/js/metadata";

import "../../../auto/js/pages/base.css"
import "../../../main/js/pages/form.css"
import { ZONE_1 } from "../metadata/Area";
import { save } from "../../../auto/js/forms/retornadosRecord/RetornadosRecordForm";
import { Gender } from "../../../auto/js/metadata/Gender";
import { displayRetornadosWorkflow } from "../widgets/RetornadosManager";
import { RetornadosComponent } from "../widgets/RetornadosComponents";

const type = {1:"PPT"};
const verifyFields = [
	{ name: "id", type: "text", x: 1, y: 2, layout: "col-md-12" },
];

const resultFields = [
    { name: "photo", type: "image", x:1, y: 1, layout:"col-md-12"},
	{ name: "identificationNumber", type: "text", x: 1, y: 2, layout: "col-md-12" },
	{ name: "name", type: "text", x: 1, y: 3, layout: "col-md-12" },
    { name: "birthdate", type: "date", x: 1, y:4, layout: "col-md-12"}
];

let VerifyForm = createFormComponent(verifyFields);

class FormComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			verifyResult: undefined
		}
    }

    verify = async (formData) => {
        let dto = form2dto(formData);
        try {
            return rest.request(getServiceUri() +  '/verify-service/verify-by-passport', "POST", dto).then(
                response => {
                    this.setState({verifyResult: response })
                }
            )
        } catch (err) {
            alert(err);
        }
    }

    render() {
        let ResultForm = createFormComponent(resultFields);

        return (
            <>
            <div className='verification row'>
                <div className='col-md-6' style={{backgroundColor: 'white'}}>
                    <h2 style={{textAlign: 'center', marginBottom: '40px'}}>{t`Verificación`}</h2>
                    <VerifyForm loadData={async () => buildEmptyObject(verifyFields)} onSubmit={this.verify} buttons={getButtons}/>
                </div>
                <div className='col-md-6'>
                    <img src={'/public/coa.png'} style={{width: '500px'}} />
            </div>
                </div>
            {(this.state.verifyResult)?
            <div className='verification row'>
                <div className='col-md-10' style={{backgroundColor: 'white'}}>
                    <RetornadosComponent loadData={async () => buildResultObject(this.state.verifyResult)}/>
                </div>
                <div className='col-md-2' style={{backgroundColor: 'white'}}>
                <button onClick={() => save( buildImportObject(this.state.verifyResult)).then((id) => {          
                        displayRetornadosWorkflow(this.props.gui, id)
                    })} style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Import`} </button>
                </div>
            </div>
            :null}
            
            </> 
        )
    }
}

export const displayVerifyForm = () => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <FormComponent />
    })
}

export const displayImportRecordForm = (gui) => {
    const readImportRecordForm = () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent gui={gui}/>,
        }
    }
    gui.goTo(readImportRecordForm, null, null)
}

const buildImportObject = (result) => {
    let face = null;
    if (result.face != null)
        face = "data:".concat("image/png", ";base64,", result.face);
    result['photo'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face != null)?false:true};
    let name = ""
    if (result.firstname != null) {
        name = result.firstname;
        if (result.lastname != null)
            name = result.firstname + " " + result.lastname;
    }
    result.name = name;
    if (result.ppt != null) {
        result.identificationNumber = result.ppt;
    }
    
    return result;
}

const buildResultObject = (result) => {
    result['photo'] = result.face;
    let name = ""
    if (result.firstname != null) {
        name = result.firstname;
        if (result.lastname != null)
            name = result.firstname + " " + result.lastname;
    }
    result.name = name;
    if (result.ppt != null) {
        result.identificationNumber = result.ppt;
    }
    
    return result;
}

const form2dto = (form) => {
    let dto = {};
    dto.id = form.id;
    if (form.name == "")
        dto.name = null;
    else
        dto.name = form.name;
    return dto;
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Verificar`} </button>
}

const getImportButton = () => {
	return <button style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Import`} </button>
}
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { VerificationTask } from "./VerificationTask";
import { CaracterizationTask } from "./CaracterizationTask";
import { rest, t } from "../../../auto/js/services";
import { StepperBrowser } from "./StepperBrowser";
import { NeedsTask } from "./NeedsTask";
import { HouseHoldTask } from "./HouseHoldTask";
import { RetornadosComponent } from "./RetornadosComponents";

export const displayRetornadosWorkflow = (gui, id) => {
  let steps = [
    t`Retornados Caracterization`,
    t`Retornados Needs`,
    t`Retornados House Hold`
  ];

  class RetornadosStepperBrowser extends StepperBrowser {
    constructor(props) {
      super(props)
      this.tasks.push(new CaracterizationTask(0, steps[0], this));
      this.tasks.push(new NeedsTask(1, steps[1], this));
      this.tasks.push(new HouseHoldTask(2, steps[2], this));
      this.start();
    }

  }

  const createRetornadosFlowBrowser = (args) => {
    let uuid = uuidv4();
    return {
        uuid,
        view: () => <RetornadosStepperBrowser gui={gui} steps={steps} welcome={() => <></>} stepperHeader={<RetornadosComponent loadData={async () => loadRetornadosData(id)} />}/>
    }
  }
  gui.goTo(createRetornadosFlowBrowser, null, null);
}

const loadRetornadosData = async (id) => {
    return await rest.read('retornados-record', id);
}
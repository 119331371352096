export const PROGRAM_ORDER_BY_ID = "ID";
export const PROGRAM_ORDER_BY_NAME = "NAME";
export const PROGRAM_ORDER_BY_DESCRIPTION = "DESCRIPTION";
export const PROGRAM_ORDER_BY_FROM_DATE = "FROM_DATE";
export const PROGRAM_ORDER_BY_TO_DATE = "TO_DATE";
export const PROGRAM_ORDER_BY_ELIGIBILITY_CRITERIA = "ELIGIBILITY_CRITERIA";
export const PROGRAM_ORDER_BY_AVAILABLE_SPACE = "AVAILABLE_SPACE";
export const PROGRAM_ORDER_BY_OPENING_HOURS = "OPENING_HOURS";
export const PROGRAM_ORDER_BY_WEBSITE = "WEBSITE";
export const PROGRAM_ORDER_BY_SERVICE_ADDRESS = "SERVICE_ADDRESS";
export const PROGRAM_ORDER_BY_CONTACT_NAME = "CONTACT_NAME";
export const PROGRAM_ORDER_BY_CONTACT_EMAIL = "CONTACT_EMAIL";
export const PROGRAM_ORDER_BY_CONTACT_NUMBER = "CONTACT_NUMBER";
export const PROGRAM_ORDER_BY_USER_ID = "USER_ID";
export const PROGRAM_ORDER_BY_REGISTRATION_INSTRUCTION = "REGISTRATION_INSTRUCTION";
export const PROGRAM_ORDER_BY_PROGRAM_TYPE = "PROGRAM_TYPE";

import React from "react";
import { ProgramType } from "../../../../auto/js/metadata/ProgramType";
import { swapObject } from "../../../../auto/js/utils";

export const programFields = [
	{name: "name", type:"text", x:1, y:2, layout:"col-md-6"},
    {name:"description", type:"text", x:2, y:2, layout:"col-md-6"},
    {name: "fromDate", type: "date", x:1, y:3, layout:"col-md-6"},
    {name: "toDate", type: "date", x:1, y:3, layout:"col-md-6"},
    {name: "eligibilityCriteria", type: "text", x:1, y:4, layout:"col-md-12"},
    {name: "registrationInstruction", type: "text", x:1, y:5, layout:"col-md-12"},
    {name: "availableSpace", type: "number", x:1, y:6, layout:"col-md-6"},
    {name: "website", type: "text", x:2, y:5, layout:"col-md-6"},
    {name: "serviceAddress", type: "text", x:1, y:7, layout:"col-md-6"},
    {name: "contactName", type: "text", x:2, y:7, layout:"col-md-6"},
    {name: "contactEmail", type: "text", x:1, y:8, layout:"col-md-6"},
    {name: "contactNumber", type: "text", x:2, y:8, layout:"col-md-6"},
];

export const form2dto = (form, dto) => {
    if (form.programType != null)
        dto.programType = swapObject(ProgramType)[form.programType]
}

export const dto2form = (dto) => {
    let form = dto;
    if (dto.programType != null)
        form.programType = ProgramType[dto.programType]
    return form;
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayNewHouseHoldForm } from '../../../auto/js/forms/houseHold/HouseHoldForm';
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import { displayNewProgramForm } from '../../../auto/js/forms/program/ProgramForm';
import { displayHouseHoldList } from '../../../auto/js/lists/houseHold/HouseHoldList';
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import { displayNeedsList } from '../../../auto/js/lists/needs/NeedsList';
import { displayProgramList } from '../../../auto/js/lists/program/ProgramList';
import { displayRetornadosRecordList } from '../../../auto/js/lists/retornadosRecord/RetornadosRecordList';
import { SIDE_MENU } from '../../../auto/js/metadata/MenuType';
import {whoami} from '../../../auto/js/users/UserInfo';
import { displayAffiliationList } from '../lists/HealthAffiliationList';
import { displayEducationAffiliationList } from '../lists/EducationAffiliationList';

import { faAddressCard, faBoxArchive, faGear, faHandsHolding, faHouse, faPeopleRoof } from '@fortawesome/pro-regular-svg-icons';
import { t } from '../../../auto/js/services';
import { displayImportRecordForm } from '../forms/VerifyForm';
import { displayEmployAffiliationList } from '../lists/EmployAffiliationList';
import { displayHousingAffiliationList } from '../lists/HousingAffiliationList';

export const menu = (gui) => {
    return {
        "home": {
            label: "Home",
            do: () => gui.goTo(gui.readWelcomePage),
            icon: <FontAwesomeIcon icon={faHouse} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Home`} />
        },
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "registro": {
                submenu: {
                    "registro": {
                        options: {
                            import: {
                                label: "Import",
                                do: () => displayImportRecordForm(gui)
                            },
                            retornados: {
                                label: "Retornados",
                                do: () => displayRetornadosRecordList(gui)
                            }
                        }
                    }
                },
                label: "Registro",
                icon: <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
			},
        }),
        ...((whoami().roles.includes('ADRES')) && {
            "my-cases": {
				label: "My Cases",
                do: () => displayAffiliationList(gui),
                icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
			}
        }),
        ...((whoami().roles.includes('ALCALDIA')) && {
                "my-cases": {
                    label: "My Cases",
                    do: () => displayEducationAffiliationList(gui),
                    icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
                }
        }),
        ...((whoami().roles.includes('CP')) && {
            "my-cases": {
                label: "My Cases",
                do: () => displayHousingAffiliationList(gui),
                icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
            }
        }),
        ...((whoami().roles.includes('PNUD')) && {
            "my-cases": {
                label: "My Cases",
                do: () => displayEmployAffiliationList(gui),
                icon: <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Register`} />
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('ALCALDIA') || whoami().roles.includes('ADRES') || whoami().roles.includes('PNUD') || whoami().roles.includes('CP')) && {
            "programs": {
                submenu: {
                    "programs": {
                        options: {
                            list: {label: "Programs", do: () => displayProgramList(gui)},
                            new: {label: "New Program", do: () => gui.goTo(displayNewProgramForm())},
                        },
                        label: "Programs"
                    },
                },
                label: "Program",
                icon: <FontAwesomeIcon icon={faHandsHolding} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Programs`} />
			},
        }),
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "house-hold": {
                submenu: {
                    "house-holds": {
                        options: {
                            list: {label: "House Holds", do: () => displayHouseHoldList(gui)},
                            new: {label: "New House Hold", do: () => displayNewHouseHoldForm(gui)},
                        },
                        label: "House Holds"
                    },
                },
                label: "House Hold",
                icon: <FontAwesomeIcon icon={faPeopleRoof} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`House Hold`} />
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "configuration": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: () => displayTranslationsList(gui)},
                            new: {label: "New Translation", do: () => displayNewTranslationsForm(gui)},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration",
                icon: <FontAwesomeIcon icon={faGear} style={{ fontSize: '1.1em', color: '#fff', marginRight: '10px', textAlign: 'left', justifyContent: 'inherit' }} title={t`Configurations`} />
            }
        })
    }
}
export const menuType = SIDE_MENU;

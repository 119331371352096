import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { getMtlbType, MtlbType } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { retornadosRecordListColumns, getMtlbTypeList, getOrderBy,  filterData, getErrorList } from "../../../../main/js/lists/retornadosRecord/RetornadosRecordListCommon"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadRetornadosRecordForm } from "../../forms/retornadosRecord/RetornadosRecordForm";
import { displayRetornadosTabsView } from "../../../../main/js/forms/RetornadosTabsView";

const RetornadosRecordListPage = ({uuid, gui}) => {
    let RetornadosRecordTable = createTableComponent(retornadosRecordListColumns);
       const buildData = async (query) => {
        let filter = query;
        let data;
        filter["retornados-record-document"] = {};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["retornados-record"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["retornados-record-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response)
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }
    return (
        <div>
            <RetornadosRecordTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} />
    	</div>
    )


}

export const displayRetornadosRecordList = (gui) => {  
	const readRetornadosRecordList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RetornadosRecordListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRetornadosRecordList)
}
const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayRetornadosTabsView(gui, rowData.id);
                }
        }
    )
    return actions;   
}

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "retornados-record/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "retornados-record/count-document/", "POST", filter);
}

export const RetornadosStatus = {
	"IN_PROCESS": 1
	,
	"APPROVED": 2
	,
	"REJECTED": 3
	,
	"ARCHIVED": 4
	
}
Object.freeze(RetornadosStatus);
const toTypeMap = {};
Object.keys(RetornadosStatus).forEach(key => {
	toTypeMap[RetornadosStatus[key]] = key;
});
export function getRetornadosStatus (value) {
	return toTypeMap[value];
}

export function getAllRetornadosStatus () {
    return Object.keys(RetornadosStatus);
}

export const RETORNADOS_STATUS_IN_PROCESS = "IN_PROCESS";
export const RETORNADOS_STATUS_APPROVED = "APPROVED";
export const RETORNADOS_STATUS_REJECTED = "REJECTED";
export const RETORNADOS_STATUS_ARCHIVED = "ARCHIVED";

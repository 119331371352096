import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useField } from "formik";

import { i18ndb, rest } from '../services';
import { useState,useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';

const buildOptions = (list) => {
	if (!list)
		return [];
	const items = [];
	for (const [key, value] of Object.entries(list)) {
		items.push(<MenuItem key={key} value={key}>{i18ndb._(value)}</MenuItem>);
	}
	return items;
}

/**
	Props:
	entity: entity containing the metadata in the backend
	metadata: content of the metadata if not taken from the backend
 */
export function SelectInput(props) {
	const [option,setOption] = useState({'':'loading...'})
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
	const { setValue } = helpers;

	useEffect(()=>{
		if (props.entity) {
			rest.list(props.entity, {})
				.then(response => {
					setOption(response)
				});
			} else {
				setOption(props.list)

			}
	},[props.entity])
	
	let options = buildOptions(option);

	const handleChange = event => {
		setValue(event.target.value);
	};

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		'label + &': {
		  marginTop: theme.spacing(3),
		},
		/* '& .MuiInputBase-input': {
		  borderRadius: 4,
		  position: 'relative',
		  backgroundColor: '#fafafa',
		  border: '1px solid',
		  borderColor: '#ebf0ed',
		  borderRadius: '10px',
		  fontSize: 16,
		  width: '100%',
		  padding: '10px 12px',
		  transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		  ]),
		  // Use the system font instead of the default Roboto font.
		  fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		  ].join(','),
		  '&:focus': {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		  },
		}, */
	  }));
  
	return (
		<FormControl variant="standard">
			<InputLabel shrink htmlFor={props.name}>
				{props.label}
        	</InputLabel>
		  <Select
			select
			id={props.name}
			value={value}
			onChange={handleChange}
			onBlur={props.onBlur}
			name={props.name}
			disabled={props.disabled}
			input={<BootstrapInput />}
			inputProps={{ 'aria-label': 'Without label' }}
			>
			{options}
			</Select>
		  </FormControl>
	);
  }

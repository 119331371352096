import * as React from 'react';
import { useField } from "formik";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr"

import { t } from "../services";


export function DateInput(props) {

    const [field, meta, helpers] = useField(props.name);
    const [language, setLanguage] = React.useState(undefined);
    const [locale, setLocal] = React.useState(undefined);
    const { value } = meta;
    const { setValue } = helpers;
    const handleDateChange = newDate => {
      setValue(newDate);
    };
    
    if (!locale || language != props.language) {
      setLanguage(props.language);
      if (props.language == "ar")
        setLocal(arLocale);
      else if (props.language == "en")
        setLocal(enLocale)
      else if (props.language == "fr")
        setLocal(frLocale);
      else
      setLocal(enLocale)
    }
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} >
          <KeyboardDatePicker
            margin="normal"
            placeholder="dd/mm/yyyy"
            id={props.id}
            label={props.label}
            format="dd/MM/yyyy"
            value={value}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': t`change date`,
            }}
			      disabled={props.disabled}
           /*  ToolbarComponent={
              (toolbarProps) => (
                  <div>
                      <CustomDateToolbar {...toolbarProps}/>
                  </div>
              )
          } */
          okLabel={t`ok`}
          cancelLabel={t`cancel`}
          />
      </MuiPickersUtilsProvider>
    );
  }
import { formState } from "../../../auto/js/forms/FormState";
import { displayReadHouseHoldForm } from "../../../auto/js/forms/houseHold/HouseHoldForm";
import { Task } from "./Task";

export class HouseHoldTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        this.gui.goTo(displayReadHouseHoldForm(this.complete), formState.getState().houseHoldId, null)
    }
}
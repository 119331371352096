export const OccupationStatus = {
	"BUSINESS_OWNER": 1
	,
	"INDEPENDENT": 2
	,
	"PAID_EMPLOYEE": 3
	,
	"UNPAID_FAMILY_ORKER": 4
	,
	"STUDENT_WHO_ALSO_WORKS": 5
	,
	"FULL_TIME_STUDENT": 6
	,
	"HOUSEWIFW": 7
	,
	"UNEMPLOYED_LOOKING_FOR_WORK_HAS_WORKED": 8
	,
	"UNEMPLOYED_LOOKING_FOR_WORK_NEVER_WORKED": 9
	,
	"RETIRED_TOO_OLD": 10
	,
	"DISABILITY_ILLNESS": 11
	,
	"MINORS": 12
	,
	"OTHER": 13
	
}
Object.freeze(OccupationStatus);
const toTypeMap = {};
Object.keys(OccupationStatus).forEach(key => {
	toTypeMap[OccupationStatus[key]] = key;
});
export function getOccupationStatus (value) {
	return toTypeMap[value];
}

export function getAllOccupationStatus () {
    return Object.keys(OccupationStatus);
}

export const OCCUPATION_STATUS_BUSINESS_OWNER = "BUSINESS_OWNER";
export const OCCUPATION_STATUS_INDEPENDENT = "INDEPENDENT";
export const OCCUPATION_STATUS_PAID_EMPLOYEE = "PAID_EMPLOYEE";
export const OCCUPATION_STATUS_UNPAID_FAMILY_ORKER = "UNPAID_FAMILY_ORKER";
export const OCCUPATION_STATUS_STUDENT_WHO_ALSO_WORKS = "STUDENT_WHO_ALSO_WORKS";
export const OCCUPATION_STATUS_FULL_TIME_STUDENT = "FULL_TIME_STUDENT";
export const OCCUPATION_STATUS_HOUSEWIFW = "HOUSEWIFW";
export const OCCUPATION_STATUS_UNEMPLOYED_LOOKING_FOR_WORK_HAS_WORKED = "UNEMPLOYED_LOOKING_FOR_WORK_HAS_WORKED";
export const OCCUPATION_STATUS_UNEMPLOYED_LOOKING_FOR_WORK_NEVER_WORKED = "UNEMPLOYED_LOOKING_FOR_WORK_NEVER_WORKED";
export const OCCUPATION_STATUS_RETIRED_TOO_OLD = "RETIRED_TOO_OLD";
export const OCCUPATION_STATUS_DISABILITY_ILLNESS = "DISABILITY_ILLNESS";
export const OCCUPATION_STATUS_MINORS = "MINORS";
export const OCCUPATION_STATUS_OTHER = "OTHER";

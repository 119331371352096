import React from "react";
import { PROGRAM_ORDER_BY_NAME } from "../../../../auto/js/metadata/ProgramOrderBy";

export const programListColumns = [
    {title: "name", field: "name"},
    {title: "fromDate", field: "fromDate"},
    {title: "toDate", field: "toDate"},
];

export const getOrderBy = () => {
    return PROGRAM_ORDER_BY_NAME
}

export const filterData = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        let fromDate = row.fromDate
        if ( fromDate !== null)
            row.fromDate = fromDate[2] + '-' + fromDate[1] + '-' + fromDate[0];
        let toDate = row.toDate
        if ( toDate !== null)
            row.toDate = toDate[2] + '-' + toDate[1] + '-' + toDate[0]; 
      newRows.push(row);
    }
    return newRows;
}
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { HouseType } from "../../../auto/js/metadata/HouseType";
import { RETORNADOS_RECORD_ORDER_BY_NAME } from "../../../auto/js/metadata/RetornadosRecordOrderBy";
import { rest, t } from "../../../auto/js/services";
import { swapObject } from "../../../auto/js/utils";



export const buildHouseHoldData = async (query, id) => {
    let filter = query;
    let data;
    filter["retornados-record"] = {houseId: id};
    filter["orderBy"] = RETORNADOS_RECORD_ORDER_BY_NAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["retornados-record"].columns.forEach(element => {
            if(element.type=='text') {
                filter["retornados-record"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    if (id != null) 
        return await getData(filter).then(response => {
            data = filterData(response);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    else
        return {data: [], totalCount: 0, page: 0}
}

const getData = async (filter) => {
    return await rest.search('retornados-record', filter)
}

const countData = async (filter) => {
        return await rest.count('retornados-record', filter);
}

export const getHouseHoldEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {
            let dto = pojoMetadata['retornados-record'].form2dto(newData);
            dto["houseId"] = id;
            if (newData.houseType != null)
                dto.houseType = swapObject(HouseType)[newData.houseType]    
            try {
                return rest.request(getServiceUri() + '/retornados-record/new-document', 'POST', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }        
        })
    editables.onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
        try {
            let dto = pojoMetadata['retornados-record'].form2dto(newData);
            if (newData.houseType != null)
                dto.houseType = swapObject(HouseType)[newData.houseType] 
            return rest.request(getServiceUri() + '/retornados-record/edit-document',  'POST', dto).then(() => resolve());
        } catch (err) {
            alert(err);
        }
    })
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                let dto = pojoMetadata['retornados-record'].form2dto(oldData);
                dto["houseId"] = null;
                return rest.request(getServiceUri() + '/retornados-record/edit-document',  'POST', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }
        })
    return editables;
}

const filterData = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        let houseType = row.houseType;
        if (houseType != null) {
			row.houseType = HouseType[houseType];
		}
      newRows.push(row);
    }
    return newRows;
}